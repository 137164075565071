import React,{Component} from 'react';
//import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
class Loginform extends Component
{
    constructor(props)
    {
        super(props)
        {

        }
    }
    render()
    {
        return(
            <div>
                <h1>hello</h1>
            </div>
        )
    }
}
export default Loginform